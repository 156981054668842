import React, { useEffect } from 'react';
import { appAnalytics } from '../../../analytics';
import { useAppSelector } from '../../../bootstrap/hooks';
import { RedeemAppContinue, RedeemScreenOpened } from '../analytics';
import { getOneLink, isTouchDevice } from '../../../loonaweb/utils';
import { useTranslation } from './../../bidease/i18n/useTranslation';
import { SuccessTemplate } from '../components/successtemplate/SuccessTemplate';

export const RedeemDone = () => {
  const translations = useTranslation();
  const { authToken } = useAppSelector((state) => state.redeem);

  useEffect(() => {
    appAnalytics.trackEvent(new RedeemScreenOpened('redeemCongratsScreen'));
  }, []);

  const handleAppLink = () => {
    appAnalytics.trackEvent(new RedeemAppContinue());
    window.location.href = getOneLink(
      {
        mediaSource: 'web_payments_dev',
        webDp: 'https%3A%2F%2Floona.app%2F',
      },
      authToken,
      !isTouchDevice() ? 'https://install.loona.app/install' : '',
    );
  };

  return (
    <SuccessTemplate
      title={translations.get('congratulations_title')}
      subtitle={translations.get('congratulations_subtitle')}
      description={<>{translations.get('congratulations_description')}</>}
      button={translations.get('continue_app_button')}
      onButtonClick={handleAppLink}
    />
  );
};
